/** @format */

import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import {
  createTimeStampAsyncThunk,
  deleteAllTimeStampWithContentAsyncThunk,
  deleteTimeStampAsyncThunk,
  getTimeStampsAsyncThunk,
  importTimeStampAsyncThunk,
  updateTimeStampAsyncThunk,
} from "../redux/pagesSlices/timeStampSlice";
import {
  getContentAsyncThunk,
  updateContentAsyncThunk,
} from "../redux/pagesSlices/contentSlice";
import { handleModel } from "../redux/layoutSlices/modelSlice";
import { parseUrlFromModel } from "../helpers/asset";
import { useParams } from "react-router-dom";
import DeleteModel from "../components/modals/DeleteModel";
import { getPerformersAsyncThunk } from "../redux/pagesSlices/performerSlice";
import axios from "axios";
import ReactPlayer from "react-player";
import { colorslist } from "../constants";
import { FaPause, FaPlay } from "react-icons/fa";

const CreateStamps = () => {
  const [loading, setLoading] = useState(false);
  const [updateFlag, setUpdateFlag] = useState(false);
  const [timestampValidation, setTimestampValidation] = useState(true);

  const [timeRanges, setTimeRanges] = useState([
    {
      startTime: "",
      endTime: "",
      name: "",
      status: "",
      description: "",
      performer: [],
      introTime: "",
    },
  ]);

  const [previewStartTime, setPreviewStartTime] = useState("00:00:00");

  const [file, setFile] = useState(null);

  const performers = useSelector(
    (state) => state?.performer?.performer?.results
  );

  console.log("The performers are:", performers);

  useEffect(() => {
    // if(!performers)
    dispatch(getPerformersAsyncThunk({ limit: 100 }));
  }, []);

  const videoRef = useRef(null);
  const params = useParams();
  console.log("🚀 ~ CreateStamps ~ params:", params);
  const dispatch = useDispatch();
  const modelArgs = useSelector(
    (state) => state.model?.modelArgs?.RightSidebarBase
  );
  const oldData = modelArgs?.args;
  const content = useSelector((state) => state.contents.content);
  console.log("🚀 ~ CreateStamps ~ content:", content);
  const timeStamps = useSelector((state) => state.timeStamp.timeStamps);
  console.log("The timestamps are:", timeStamps);

  useEffect(() => {
    let queryParams = {};

    dispatch(getContentAsyncThunk({ id: params?.id, queryParams }));
    dispatch(
      getTimeStampsAsyncThunk({
        contentId: params?.id,
        sortBy: "name:ase",
        limit: 60,
      })
    );
  }, [dispatch, params?.id]);

  useEffect(() => {
    if (
      previewStartTime !== content?.prevStartTime &&
      content?.prevStartTime !== "0"
    ) {
      setPreviewStartTime(content?.prevStartTime);
    }
  }, [content]);

  const timeRangeNameFormat = /^\d{2}-.+$/;

  useEffect(() => {
    if (timeStamps && timeStamps.results) {
      setTimeRanges(
        timeStamps.results.map((timeStamp, index) => {
          if (timeRangeNameFormat.test(timeStamp?.name)) {
            const timeStampName = timeStamp?.name.slice(3);

            return {
              name: `${
                index >= 9
                  ? `${index + 1}-${timeStampName}`
                  : `0${index + 1}-${timeStampName}`
              }`,
              description: timeStamp?.description,
              status: timeStamp?.status,
              startTime: timeStamp?.startTime,
              endTime: timeStamp?.endTime,
              id: timeStamp?.id,
              performer: timeStamp?.performer,
              introTime: timeStamp?.introTime,
            };
          } else {
            return {
              name: `${
                index >= 9
                  ? `${index + 1}-${timeStamp?.name}`
                  : `0${index + 1}-${timeStamp?.name}`
              }`,
              description: timeStamp?.description,
              status: timeStamp?.status,
              startTime: timeStamp?.startTime,
              endTime: timeStamp?.endTime,
              id: timeStamp?.id,
              performer: timeStamp?.performer,
              introTime: timeStamp?.introTime,
            };
          }
        })
      );
    }
  }, [timeStamps]);

  const openModal = () => {
    dispatch(handleModel({ model: "DeleteModel", state: true }));
  };

  const previewStartTimeUpdateHandler = () => {
    if (content?.prevStartTime === previewStartTime) {
      console.log("returning...");
      return;
    }

    console.log("returning... dispatching");
    dispatch(
      updateContentAsyncThunk({
        id: params?.id,
        data: {
          prevStartTime: previewStartTime,
        },
      })
    );
  };

  const handlePreviewTimeChange = (value) => {
    const formatTimeWithColon = (time) =>
      time
        .replace(/[^0-9]/g, "")
        .slice(0, 6)
        .replace(/(\d{2})(?=\d)/g, "$1:");

    setPreviewStartTime(formatTimeWithColon(value));
  };

  const handlePreviewKeyDown = (event) => {
    let value = event.target.value;
    if (event.key === "ArrowUp") {
      value = increaseTime(value, 1);
    } else if (event.key === "ArrowDown") {
      value = decreaseTime(value, 1);
    }

    handlePreviewTimeChange(value);
  };

  console.log("previewTime: ", previewStartTime);

  const handleChangeTimeRange = (index, field, value) => {
    const formatTimeWithColon = (time) =>
      time
        .replace(/[^0-9]/g, "")
        .slice(0, 6)
        .replace(/(\d{2})(?=\d)/g, "$1:");

    const newTimeRanges = [...timeRanges];
    console.log(newTimeRanges, "here is new time ranges");
    if (field === "startTime" || field === "endTime") {
      value = formatTimeWithColon(value);
    }
    // if(field === "name"){
    //   value = value.slice(3);
    // }
    newTimeRanges[index][field] = value;
    setTimeRanges(newTimeRanges);
    handleLoadedMetadata(index);
  };

  const generateShareableUrl = (currentTime) => {
    const baseUrl = `${window.location.origin}/#/player/${params.id}`;
    const currentChapter = findCurrentChapter(currentTime, timeStamps.results);
    let timeString;
    if (currentChapter) {
      timeString = currentTime?.toFixed(0)?.padStart(6, "0");
      const formattedChapter = formatChapter(currentChapter);
      return `${baseUrl}?t=${timeString}&${formattedChapter}`;
    }
    return `${baseUrl}`;
  };

  const findCurrentChapter = (currentTime, timeStamps) => {
    // console.log("TimeStamps are: ", timeStamps)
    const currentChapter =
      timeStamps &&
      timeStamps?.find((chapter) => {
        const startTimeInSeconds = timeStringToSeconds(chapter.startTime);
        const endTimeInSeconds = timeStringToSeconds(chapter.endTime);
        return (
          currentTime >= startTimeInSeconds && currentTime <= endTimeInSeconds
        );
      });
    return currentChapter;
  };

  const formatChapter = (chapter) => {
    const { name, startTime, endTime } = chapter;
    const formattedName = name.replace(/\s+/g, "_");
    const formattedStartTime = startTime.replace(/:/g, "_");
    const formattedEndTime = endTime.replace(/:/g, "_");
    return `${content?.name}_${formattedName}_S_${formattedStartTime}_E_${formattedEndTime}`;
  };

  function timeStringToSeconds(timeString) {
    var timeParts = timeString?.split(":");
    var hours = parseInt(timeParts?.[0]);
    var minutes = parseInt(timeParts?.[1]);
    var seconds = hours * 3600 + minutes * 60 + parseInt(timeParts?.[2]);
    return seconds;
  }

  const handlePerformerSelect = (value, index, field) => {
    let newValue = [...timeRanges[index][field]];
    console.log("newValue is:", newValue);

    if (newValue?.includes(value)) {
      newValue = newValue?.filter((val) => val !== value);
      console.log("newValue after removal is:", newValue);
    } else {
      newValue.push(value);
      console.log("newValue after addition is:", newValue);
    }

    const newTimeRanges = [...timeRanges];
    newTimeRanges[index][field] = newValue; // Correctly set the updated array
    setTimeRanges(newTimeRanges);
  };

  const handleIntroKeyPress = (event, index, field) => {
    const increment = 1; // You can change this increment as desired
    console.log("Event target value is:", event.target.value);

    // let newValue = [...timeRanges[index][field]];;
    let newValue = timeRanges[index][field];
    if (event.key === "ArrowUp") {
      newValue = increaseIntroTime(newValue, increment);
    } else if (event.key === "ArrowDown") {
      newValue = decreaseIntroTime(newValue, increment);
    }
    handleChangeIntroTimeRange(index, field, newValue);
  };

  const handleChangeIntroTimeRange = (index, field, value) => {
    const newVal = Number(value);

    if (newVal > 60) {
      value = "60";
    }

    const newTimeRanges = [...timeRanges];
    newTimeRanges[index][field] = value;
    setTimeRanges(newTimeRanges);
  };

  const increaseIntroTime = (time, increment) => {
    let newTime = Number(time);
    if (newTime < 60) {
      newTime += increment;
      return String(newTime);
    }

    toast.error("Intro Time cannot exceed 60 seconds.");
    return time;
  };

  const decreaseIntroTime = (time, increment) => {
    let newTime = Number(time);
    if (newTime > 1) {
      newTime -= increment;
      return String(newTime);
    }

    toast.error("Intro Time cannot be less than 1 second.");
    return time;
  };

  const handleKeyPress = (event, index, field) => {
    const increment = 1; // You can change this increment as desired
    console.log("Event target value is:", event.target.value);

    // let newValue = [...timeRanges[index][field]];;
    let newValue = timeRanges[index][field];

    if (event.key === "ArrowUp") {
      newValue = increaseTime(newValue, increment);
    } else if (event.key === "ArrowDown") {
      if (field === "endTime") {
        const startTime = timeRanges[index]["startTime"];
        newValue = decreaseTime(newValue, increment, startTime);
        if (newValue === "23:59:59" && startTime === "00:00:00") {
          newValue = startTime;
        }
      } else {
        newValue = decreaseTime(newValue, increment);
      }
    }

    handleChangeTimeRange(index, field, newValue);
  };

  const increaseTime = (timeString, increment) => {
    const timeArray = timeString.split(":");
    let [hours, minutes, seconds] = timeArray.map(Number);

    seconds += increment;
    if (seconds >= 60) {
      seconds = 0;
      minutes++;
    }
    if (minutes >= 60) {
      minutes = 0;
      hours++;
    }
    hours %= 24;

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  const decreaseTime = (timeString, increment, startTime) => {
    console.log("This is the timestring: ", timeString);
    const timeArray = timeString.split(":");
    let [hours, minutes, seconds] = timeArray.map(Number);

    seconds -= increment;
    if (seconds < 0) {
      seconds = 59;
      minutes--;
    }
    if (minutes < 0) {
      minutes = 59;
      hours--;
    }
    if (hours < 0) {
      hours = 23;
    }

    const newTimeString = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    // Ensure the decreased time doesn't go before the start time
    if (startTime && newTimeString < startTime) {
      return startTime;
    }
    return newTimeString;
  };

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time - hours * 3600) / 60);
    const seconds = Math.floor(time - hours * 3600 - minutes * 60);

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  const handleProgressBarClick = (e) => {
    const progressBar = e.target;
    const rect = progressBar.getBoundingClientRect();
    const offsetX = e.clientX - rect.left;
    const width = rect.width;
    const clickTime = (offsetX / width) * videoRef.current.duration;

    const newTimeRange = {
      startTime:
        timeRanges.length === 0
          ? "00:00:00"
          : timeRanges[timeRanges.length - 1].endTime,
      endTime: formatTime(clickTime),
      name: `CH ${timeRanges.length + 1}`,
      description: "",
      status: "publish",
    };

    setTimeRanges([...timeRanges, newTimeRange]);
  };

  const handleAddTimeRange = () => {
    setTimeRanges([
      ...timeRanges,
      { startTime: "", endTime: "", name: "", status: "", description: "" },
    ]);
  };

  const handleAddTimeRangeInNextIndex = (index) => {
    if (
      index !== null &&
      index !== undefined &&
      index < timeRanges.length - 1
    ) {
      const newTimeRanges = [...timeRanges];

      // Calculate the new start and end times based on adjacent indices
      const upperIndexEndTime = newTimeRanges[index].endTime;
      const newStartTime = addSeconds(upperIndexEndTime, 1); // Start time of the new timestamp
      const newEndTime = addSeconds(newStartTime, 1); // End time of the new timestamp

      // Increment start time of the timestamp below by four seconds
      const lowerIndex = index + 1;
      if (lowerIndex < newTimeRanges.length) {
        if (newTimeRanges[lowerIndex].startTime <= newEndTime) {
          newTimeRanges[lowerIndex].startTime = addSeconds(newEndTime, 1);
        }
      }

      // Insert the new time stamp with the calculated times
      newTimeRanges.splice(index + 1, 0, {
        startTime: newStartTime,
        endTime: newEndTime,
        name: `CH${(extractNumber(newTimeRanges[index].name) + 1)
          .toString()
          .padStart(3, "0")}`,
        description: "",
        status: "publish",
      });

      // setTimeRanges(newTimeRanges);
      setTimeRanges(
        newTimeRanges.map((newTimeRange, index) => {
          if (timeRangeNameFormat.test(newTimeRange?.name)) {
            const timeStampName = newTimeRange?.name.slice(3);

            return {
              name: `${
                index >= 9
                  ? `${index + 1}-${timeStampName}`
                  : `0${index + 1}-${timeStampName}`
              }`,
              description: newTimeRange?.description,
              status: newTimeRange?.status,
              startTime: newTimeRange?.startTime,
              endTime: newTimeRange?.endTime,
              id: newTimeRange?.id,
            };
          } else {
            return {
              name: `${
                index >= 9
                  ? `${index + 1}-${newTimeRange?.name}`
                  : `0${index + 1}-${newTimeRange?.name}`
              }`,
              description: newTimeRange?.description,
              status: newTimeRange?.status,
              startTime: newTimeRange?.startTime,
              endTime: newTimeRange?.endTime,
              id: newTimeRange?.id,
            };
          }
        })
      );
    }
  };

  // Function to extract number from the time stamp name
  const extractNumber = (name) => {
    const matches = name.match(/\d+/);
    return matches ? parseInt(matches[0]) : 0;
  };

  // Function to add seconds to a time in "HH:mm:ss" format
  const addSeconds = (time, seconds) => {
    const [hours, minutes, oldSeconds] = time.split(":").map(Number);
    const newSeconds = oldSeconds + seconds;
    const newMinutes = minutes + Math.floor(newSeconds / 60);
    const newHours = hours + Math.floor(newMinutes / 60);
    return `${padZero(newHours)}:${padZero(newMinutes % 60)}:${padZero(
      newSeconds % 60
    )}`;
  };

  // Function to pad zero to single digit numbers
  const padZero = (num) => {
    return num.toString().padStart(2, "0");
  };

  const deleteTimeRange = (id) => {
    dispatch(
      deleteTimeStampAsyncThunk({
        id,
        contentId: params?.id,
        callBack: () => {
          // Callback logic if needed
        },
      })
    );
  };

  const deleteTimeRangeNewly = (index) => {
    // Filter out the time range at the specified index
    const updatedTimeRanges = timeRanges.filter((_, i) => i !== index);
    setTimeRanges(updatedTimeRanges);
  };

  const autoGenerateChapters = async () => {
    if (timeRanges.length === 0) {
      const totalDuration = videoRef.current?.duration;
      console.log("🚀 ~ autoGenerateChapters ~ totalDuration:", totalDuration);
      if (!isNaN(totalDuration) && totalDuration !== 0) {
        const segmentDuration = totalDuration / 12;
        const chapters = [];
        let startTime = "00:00:00";
        let endTime = "";
        let chapterNum = 1;
        const step = Math.floor(Math.random() * 10) + 1; // Random step between 1 and 10

        for (let i = 0; i < 12; i++) {
          endTime = formatTime(segmentDuration * (i + 1));
          const name = `${i >= 9 ? `${i + 1}` : `0${i + 1}`}-CH${chapterNum
            .toString()
            .padStart(3, "0")}`;
          const description = "";
          const status = "publish";
          chapters.push({ startTime, endTime, name, status, description });
          startTime = addSeconds(endTime, 1); // Increment start time by the step
          chapterNum += step;
        }

        setTimeRanges(chapters);
      }
    }
  };

  useEffect(() => {
    const timeout = setTimeout(autoGenerateChapters, 6000);
    return () => clearTimeout(timeout);
  }, [timeRanges.length]);
  // }, [timeRanges.length, autoGenerateChapters]);

  const timePattern = /^\d{2}:\d{2}:\d{2}$/;

  const createCollectionHandler = async (data, { resetForm }) => {
    let isValid = true;
    let message = "";
    const payload = timeRanges.map(
      ({
        startTime,
        endTime,
        name,
        status,
        description,
        performer,
        introTime,
      }) => {
        if (!timePattern.test(startTime) || !timePattern.test(endTime)) {
          isValid = false;
          message =
            "Error! One or More of the timestamps do not follow the HH:MM:SS format. Please edit and ensure that it does.";
        }

        if (introTime > 60 || introTime < 1) {
          isValid = false;
          message =
            "Error! One or More of the timestamp's Intro Time is not Valid. Please edit and ensure that it is.";
        }

        return {
          startTime,
          endTime,
          contentId: params?.id,
          name,
          description,
          status: status ? status : "publish",
          performer,
          introTime,
        };
      }
    );

    if (!isValid) {
      toast.error(message);
      return;
    }

    setLoading(true);

    try {
      if (timeStamps?.results?.length > 0) {
        const promises = payload.map((timeStamp, index) => {
          if (timeStamps?.results[index]?.id) {
            return dispatch(
              updateTimeStampAsyncThunk({
                id: timeStamps?.results[index]?.id,
                data: timeStamp,
              })
            );
          } else {
            return dispatch(
              createTimeStampAsyncThunk({
                data: timeStamp,
              })
            );
          }
        });

        await Promise.all(promises);
      } else {
        await dispatch(
          createTimeStampAsyncThunk({
            data: payload,
          })
        );
      }

      // All timestamps updated successfully
      toast.success("All TimeStamps Updated Successfully!", {
        autoClose: false,
      });
      setLoading(false);

      closeModel();
      setTimeRanges([
        {
          startTime: "",
          endTime: "",
          name: "",
          status: "",
          description: "",
          performer: [],
        },
      ]);
      resetForm();
    } catch (error) {
      console.error("Error updating timestamps:", error);
      // Handle error here
    }
    setLoading(false);

    await dispatch(
      getTimeStampsAsyncThunk({ contentId: params?.id, limit: 60 })
    );
  };

  const closeModel = () => {
    dispatch(handleModel({ model: "RightSidebarBase", state: false }));
  };

  const handleDeleteAll = () => {
    try {
      dispatch(
        deleteAllTimeStampWithContentAsyncThunk({ contentId: params?.id })
      );
    } catch (error) {
      toast.error("Failed to delete metadata. Please Try Again!", {
        autoClose: true,
      });
    }
  };

  const handleDeleteBeforeImport = async () => {
    await dispatch(
      deleteAllTimeStampWithContentAsyncThunk({ contentId: params?.id })
    );
    handleImportAfterDelete();
  };

  // const handleBackup = () => {
  //   const headers = Object.keys(timeStamps?.results[0]);
  //   const csv = [
  //     headers.join(","),
  //     ...timeStamps?.results.map((item) =>
  //       headers.map((header) => item[header]).join(",")
  //     ),
  //   ].join("\n");
  //   const blob = new Blob([csv], { type: "text/csv" });
  //   const url = URL.createObjectURL(blob);
  //   const link = document.createElement("a");
  //   link.href = url;
  //   link.setAttribute("download", "timestamps.csv");
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  //   URL.revokeObjectURL(url);
  // };

  const currentTime = new Date();

  const formattedTime = currentTime
    .toLocaleString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
    .replace(", ", "_")
    .replace(/\//g, "-");

  console.log("formattedTime>>>>>>>>>>>", formattedTime);

  //   const handleBackup = () => {
  //     if (!timeStamps?.results?.length) return;

  //     console.log("The timestamps result is:",timeStamps?.results)

  //     const headers = Object.keys(timeStamps.results[0]);

  //     console.log("Headers are:", headers)

  //     const escapeCSVValue = (value) => {
  //         if (typeof value === 'string') {
  //             return `"${value.replace(/"/g, '""')}"`;
  //         }
  //         if (Array.isArray(value) || (typeof value === 'object' && value !== null)) {
  //             return `"${JSON.stringify(value).replace(/"/g, '""')}"`;
  //         }
  //         return value;
  //     };

  //     const flattenObject = (obj) => {
  //         return headers.map((header) => escapeCSVValue(obj[header])).join(",");
  //     };

  //     const csv = [
  //         headers.join(","),
  //         ...timeStamps.results.map(flattenObject),
  //     ].join("\n");

  //     const blob = new Blob([csv], { type: "text/csv" });
  //     const url = URL.createObjectURL(blob);
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.setAttribute("download", `${content?.originalName}_${formattedTime}_timestamps.csv`);
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //     URL.revokeObjectURL(url);
  // };

  console.log("TimeRange");

  const handleBackup = () => {
    if (!timeStamps?.results?.length) return;

    console.log("The timestamps result is:", timeStamps?.results);

    // Add 'shareableUrl' to headers
    console.log(timeStamps.results);
    const headers = [...Object.keys(timeStamps.results[0]), "shareAbleUrl"];

    console.log("Headers are:", headers);

    const escapeCSVValue = (value) => {
      if (typeof value === "string") {
        return `"${value.replace(/"/g, '""')}"`;
      }
      if (
        Array.isArray(value) ||
        (typeof value === "object" && value !== null)
      ) {
        return `"${JSON.stringify(value).replace(/"/g, '""')}"`;
      }
      return value;
    };

    const flattenObject = (obj) => {
      // Include 'shareableUrl' in the flattened object
      // console.log("The currentTime is:", timeStringToSeconds(obj.startTime))
      const flattened = headers
        .map((header) => {
          if (header === "shareAbleUrl") {
            return escapeCSVValue(
              generateShareableUrl(timeStringToSeconds(obj.startTime))
            );
          }
          return escapeCSVValue(obj[header]);
        })
        .join(",");
      console.log(flattened);
      return flattened;
    };

    const csv = [
      headers.join(","),
      ...timeStamps.results.map(flattenObject),
    ].join("\n");

    console.log("csv is:", csv);

    const blob = new Blob([csv], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `${content?.originalName}_${formattedTime}_timestamps.csv`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const handleContentStatus = (currentStatus) => {
    console.log("calllll status", currentStatus);
    const newStatus = currentStatus === "public" ? "private" : "public";

    dispatch(
      updateContentAsyncThunk({
        id: params?.id,
        data: {
          status: newStatus,
        },
      })
    );
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFile(file, () => {
      console.log("File set:", file);
    });
  };

  const handleImportAfterDelete = async () => {
    await dispatch(
      importTimeStampAsyncThunk({ data: { file: file, contentId: params?.id } })
    );
    // setUpdateFlag(!updateFlag)
    await dispatch(
      getTimeStampsAsyncThunk({ contentId: params?.id, limit: 60 })
    );
  };

  const handleImport = () => {
    try {
      console.log("File to import:", file);
      if (!file) {
        toast.error("No File Selected! Please first upload a .csv file!", {
          autoClose: true,
        });
        return;
      }
      openModal();
    } catch (error) {
      toast.error("Failed to Import Timestamps. Please Try Again!");
    }
  };

  ///////////////////////////////////////
  ///////////////////////////////////////
  ///////////////////////////////////////
  ///////////////////////////////////////
  const videoRefs = useRef([]);
  const [videoDuration, setVideoDuration] = useState(1);
  //const [volumeLevels, setVolumeLevels] = useState(Array(timeRanges.length).fill(1));

  const parseTime = (time) => {
    const [hours, minutes, seconds] = time.split(":").map(Number);

    return hours * 3600 + minutes * 60 + seconds;
  };

  const formatTimes = (seconds) => {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.floor(seconds % 60);
    return `${m < 10 ? "0" : ""}${m}:${s < 10 ? "0" : ""}${s}`;
  };

  const handlePlay = (index) => {
    const video = videoRefs.current[index];
    const startTime = parseTime(timeRanges[index].startTime);
    const endTime = parseTime(timeRanges[index].endTime);

    //video.currentTime = startTime;
    if (!isFinite(startTime) || !isFinite(endTime)) {
      console.error("Invalid time values:", { startTime, endTime });
      return;
    }

    video.play();

    const checkTime = () => {
      if (video.currentTime >= endTime) {
        video.pause();
        video.removeEventListener("timeupdate", checkTime);
      }
    };

    video.addEventListener("timeupdate", checkTime);
  };

  const handlePause = (index) => {
    const video = videoRefs.current[index];
    video.pause();
  };

  // State to track the playing status of each video
  const [isPlaying, setIsPlaying] = useState({});

  // Function to handle play button click
  const handlePlayClick = (index) => {
    setIsPlaying((prevState) => ({ ...prevState, [index]: true }));
    handlePlay(index);
  };

  // Function to handle pause button click
  const handlePauseClick = (index) => {
    setIsPlaying((prevState) => ({ ...prevState, [index]: false }));
    handlePause(index);
  };

  // const handleVolumeChange = (index, event) => {
  //   const volume = event.target.value;
  //   const video = videoRefs.current[index];
  //   video.volume = volume;

  //   setVolumeLevels((prev) => {
  //     const newVolumes = [...prev];
  //     newVolumes[index] = volume;
  //     return newVolumes;
  //   });
  // };

  const handleLoadedMetadata = (index) => {
    const video = videoRefs.current[index];
    if (!video) return;
    const startTime = parseTime(timeRanges[index].startTime);
    const endTime = parseTime(timeRanges[index].endTime);

    if (!isFinite(startTime) || !isFinite(endTime)) {
      console.error("Invalid time values:", { startTime, endTime });
      return;
    }
    setVideoDuration(video.duration);
    video.currentTime = startTime;

    const videoContainer = video.parentElement;
    const durationDisplay = videoContainer.querySelector(".duration-display");
    if (durationDisplay) {
      durationDisplay.textContent = `${formatTimes(
        video.currentTime
      )} / ${formatTimes(endTime)}`;
    }

    video.addEventListener("timeupdate", () => {
      if (durationDisplay) {
        durationDisplay.textContent = `${formatTimes(
          video.currentTime
        )} / ${formatTimes(endTime)}`;
      }
    });
  };

  // Update video control display
  // const updateVideoDisplay = (index) => {
  //   const video = videoRefs.current[index];
  //   if (!video) return;

  //   const startTime = parseTime(timeRanges[index].startTime);
  //   const endTime = parseTime(timeRanges[index].endTime);
  //   setVideoDuration(video.duration);
  //   video.currentTime = startTime;

  //   const videoContainer = video.parentElement;
  //   const durationDisplay = videoContainer.querySelector('.duration-display');
  //   if (durationDisplay) {
  //     durationDisplay.textContent = `${formatTimes(video.currentTime)} / ${formatTimes(endTime)}`;
  //   }

  //   video.addEventListener('timeupdate', () => {
  //     if (durationDisplay) {
  //       durationDisplay.textContent = `${formatTimes(video.currentTime)} / ${formatTimes(endTime)}`;
  //     }
  //   });
  // };

  const calculateBackgroundStyle = (timeRange) => {
    const startTime = parseTime(timeRange?.startTime);
    const endTime = parseTime(timeRange?.endTime);
    const duration = videoDuration || 1; // Use the state for duration

    const left = (startTime / duration) * 100;
    const width = ((endTime - startTime) / duration) * 100;

    return { left: `${left}%`, width: `${width}%` };
  };

  // const [videoSrcs, setVideoSrcs] = useState([]);

  // const fetchTrimmedVideo = async (index) => {
  //   const startTime = timeRanges[index].startTime;
  //   const endTime = timeRanges[index].endTime;
  //   let videoUrl = parseUrlFromModel(content);
  //   const url = `http://localhost:6063/content/api/perfomer/perfomer/trim-video?url=${encodeURIComponent(
  //     videoUrl
  //   )}&startTime=${startTime}&endTime=${endTime}`;
  //   // http://localhost:6063/content/api/perfomer/perfomer/trim-video?url=https://pub-049ff98eab794cb3866391f656ea8c6c.r2.dev/20MAY2024_USs.mp4&startTime=00:00:00&endTime=00:00:20
  //   try {
  //     const response = await axios.get(url);
  //     if (response.status === 200) {
  //       setVideoSrcs((prevVideoSrcs) => {
  //         const newVideoSrcs = [...prevVideoSrcs];
  //         newVideoSrcs[index] = url;
  //         return newVideoSrcs;
  //       });
  //     } else {
  //       console.error("Failed to fetch trimmed video");
  //     }
  //   } catch (error) {
  //     console.error("Failed to fetch trimmed video", error);
  //   }
  // };

  // useEffect(() => {
  //   if (timeRanges && timeRanges.length > 0) {
  //     timeRanges.forEach((_, index) => fetchTrimmedVideo(index));
  //   }
  // }, [content, timeRanges]);

  // const handleLoadedMetadata = (index) => {
  //   const video = videoRefs.current[index];
  //   const startTime = timeStringToSeconds(timeRanges[index].startTime);
  //   video.currentTime = startTime;
  // };

  useEffect(() => {
    timeRanges.forEach((_, index) => {
      handleLoadedMetadata(index);
    });
  }, [timeRanges]);

  const handleCaptcherThumbnail = (timestamps) => {
    // ffmpeg(
    //   parseUrlFromModel(content))
    //     timestamps: ['50%'], // 30 seconds of timeStamps 3 entrie

    const thirdEntry = timestamps?.results[2];

    // Function to add seconds to a given time string (in format HH:MM:SS)
    const addSecondsToTime = (time, secondsToAdd) => {
      const [hours, minutes, seconds] = time.split(":").map(Number);
      const totalSeconds = hours * 3600 + minutes * 60 + seconds + secondsToAdd;

      // Convert total seconds back to HH:MM:SS
      const newHours = Math.floor(totalSeconds / 3600)
        .toString()
        .padStart(2, "0");
      const newMinutes = Math.floor((totalSeconds % 3600) / 60)
        .toString()
        .padStart(2, "0");
      const newSeconds = (totalSeconds % 60).toString().padStart(2, "0");

      return `${newHours}:${newMinutes}:${newSeconds}`;
    };

    // Calculate 30 seconds after the startTime of the third entry
    const time = addSecondsToTime(thirdEntry.startTime, 30);
    console.log("🚀 ~ handleCaptcherThumbnail ~ time:", time);

    axios
      .patch(
        `${process.env.REACT_APP_contentBasePath}user/contents/${content?.id}`,
        {
          videoUrl: parseUrlFromModel(content), // videoUrl and time in body
          time: time,
          originalName1: content?.originalName,
        }
      )
      .then((response) => {
        console.log("🚀 ~ .then ~ response:", response);
        if (response.status === 200) {
          toast.success("Captured thumbnail successfully", {
            autoClose: false,
          });
        } else {
          console.error("Failed to fetch trimmed video");
        }
      })
      .catch((error) => {
        console.error("Failed to fetch trimmed video", error);
      });
  };
  return (
    <div className="container mb-3">
      <h2 className="mb-4">Custom Video Playback with Chapter Control</h2>

      <h4 className="mt-4 mb-4">Name : "{content?.originalName}"</h4>

      <Formik
        initialValues={{
          ...(oldData?.id
            ? {
                ...oldData,
                video: oldData?.video?.id,
                id: undefined,
              }
            : {
                name: "",
                description: "",
                startTime: "",
                endTime: "",
                video: "",
                status: "",
              }),
        }}
        onSubmit={createCollectionHandler}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          values,
          errors,
          touched,
        }) => (
          <div className="create-course-sibebar">
            <video
              controls
              style={{ width: "100%", margin: "auto" }}
              src={parseUrlFromModel(content)}
              ref={videoRef}
            />

            <div className="progress" onClick={handleProgressBarClick}>
              <div
                className="progress-bar"
                role="progressbar"
                style={{ width: "0%" }}
                aria-valuenow="0"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>

            {/* Buttons */}
            <div className="d-flex alignitems-center flex-wrap gap-3 mt-3">
              <button
                onClick={handleAddTimeRange}
                className="btn btn-secondary btn-block"
              >
                +
              </button>
              <button
                className="btn btn-dark btn-block delete-btn"
                onClick={handleDeleteAll}
              >
                Delete All Meta Data
              </button>

              <label
                htmlFor="fileInput"
                className="btn btn-secondary  File-input"
              >
                <input type="file" onChange={handleFileChange} />
                Upload a File
              </label>

              <button
                className="btn btn-dark delete-btn"
                onClick={handleImport}
              >
                Import
              </button>

              <DeleteModel
                heading={"Previous Metadata/Timestamps"}
                text={
                  "Importing timestamps will cause the metadata to be overwritten. Please be certain with your choice."
                }
                confirmationCallback={handleDeleteBeforeImport}
              />

              <button
                className="btn btn-secondary delete-btn "
                onClick={handleBackup}
              >
                Backup/Export
              </button>

              <button
                className="btn btn-secondary delete-btn "
                onClick={() => handleContentStatus(content?.status)}
              >
                Private/Public
              </button>

              {/* <div className="col-lg-2">
                  <label>
                    <h5 style={{display: "inline-block"}}>Preview Start: </h5>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    pattern="[0-9]{2}:[0-9]{2}:[0-9]{2}"
                    placeholder="hh:mm:ss"
                    value={content?.previewTime ?? "00:00:00"}
                    onChange={(e) =>

                    }
                    onKeyDown={(e) => handleKeyPress(e, index, "startTime")}
                  />
                </div> */}

              <label>
                <h5 style={{ display: "inline-block" }}>Preview Start: </h5>
              </label>

              <input
                type="text"
                // className="form-control"
                pattern="[0-9]{2}:[0-9]{2}:[0-9]{2}"
                placeholder="hh:mm:ss"
                value={previewStartTime}
                onChange={(e) => handlePreviewTimeChange(e.target.value)}
                onKeyDown={(e) => handlePreviewKeyDown(e)}
              />

              <button
                className="btn btn-secondary delete-btn "
                onClick={() => previewStartTimeUpdateHandler()}
              >
                Update Preview Start
              </button>

              <button
                className="btn btn-secondary delete-btn "
                onClick={() => handleCaptcherThumbnail(timeStamps)}
              >
                Save 3 Chapter 30Sec Thumbnail
              </button>
            </div>

            {timeRanges?.map((timeRange, index) => (
              <div className="Create-box row mb-3 mt-3" key={index}>
                <div className="col-lg-2">
                  <label>
                    <h5>CH Name</h5>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Your chapter Name"
                    value={
                      timeRange &&
                      // `${index >= 9 ? `${index+1}` : `0${index + 1}`}-${                                                                   // >>=====>  ${index >= 9 ? `${index >= 99 ? `${index+1}`: `0${index+1}`}` : `00${index + 1}`}-${timeRange?.name}
                      timeRange?.name
                      // }`
                    }
                    onChange={(e) =>
                      handleChangeTimeRange(index, "name", e.target.value)
                    }
                  />
                </div>

                <div className="col-lg-2">
                  <label>
                    <h5>Intro Time</h5>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    // pattern="^(?:[1-9]|[1-5][0-9]|60)$"
                    placeholder="1-60 seconds"
                    value={timeRange.introTime}
                    onChange={(e) =>
                      handleChangeIntroTimeRange(
                        index,
                        "introTime",
                        e.target.value
                      )
                    }
                    onKeyDown={(e) =>
                      handleIntroKeyPress(e, index, "introTime")
                    }
                  />
                </div>

                <div className="col-lg-2">
                  <label>
                    <h5>Status</h5>
                  </label>

                  <div className="checkbox-flex-box">
                    <label htmlFor={`publish_${index}`} className="mr-2">
                      Publish
                      <input
                        type="checkbox"
                        id={`publish_${index}`}
                        checked={timeRange.status === "publish"}
                        onChange={(e) =>
                          handleChangeTimeRange(
                            index,
                            "status",
                            e.target.checked ? "publish" : "hide"
                          )
                        }
                      />
                    </label>
                    <label htmlFor={`hide_${index}`} className="mr-2">
                      Hide/Play
                      <input
                        type="checkbox"
                        id={`hide_${index}`}
                        checked={timeRange.status === "hide"}
                        onChange={(e) =>
                          handleChangeTimeRange(
                            index,
                            "status",
                            e.target.checked ? "hide" : "publish"
                          )
                        }
                      />
                    </label>
                    <label htmlFor={`forcePlay_${index}`}>
                      Force Hide
                      <input
                        type="checkbox"
                        id={`forcePlay_${index}`}
                        checked={timeRange.status === "force play"}
                        onChange={(e) =>
                          handleChangeTimeRange(
                            index,
                            "status",
                            e.target.checked ? "force play" : "publish"
                          )
                        }
                      />
                    </label>
                  </div>
                </div>

                {/* Time range fields */}
                <div className="col-lg-2">
                  <label>
                    <h5>Start Time</h5>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    pattern="[0-9]{2}:[0-9]{2}:[0-9]{2}"
                    placeholder="hh:mm:ss"
                    value={timeRange.startTime}
                    onChange={(e) =>
                      handleChangeTimeRange(index, "startTime", e.target.value)
                    }
                    onKeyDown={(e) => handleKeyPress(e, index, "startTime")}
                  />
                </div>
                <div className="col-lg-2">
                  <label>
                    <h5>End Time</h5>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    pattern="[0-9]{2}:[0-9]{2}:[0-9]{2}"
                    placeholder="hh:mm:ss"
                    value={timeRange.endTime}
                    onChange={(e) =>
                      handleChangeTimeRange(index, "endTime", e.target.value)
                    }
                    onKeyDown={(e) => handleKeyPress(e, index, "endTime")}
                  />
                </div>

                <div className="col-lg-2">
                  {timeStamps?.results?.length > 0 && (
                    <>
                      <label>
                        <h5>Performers</h5>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Selected Performers"
                        value={
                          timeRange?.performer?.length > 0
                            ? performers
                                .reduce((acc, performer) => {
                                  if (
                                    timeRange.performer.includes(performer.id)
                                  ) {
                                    acc.push(performer.name);
                                  }
                                  return acc;
                                }, [])
                                .join(", ")
                            : ""
                        }
                        disabled
                      />

                      <div className="performers-list">
                        {performers &&
                          performers?.map((performer) => (
                            <p
                              name={performer?.name}
                              onClick={() =>
                                handlePerformerSelect(
                                  performer?.id,
                                  index,
                                  "performer"
                                )
                              }
                              key={performer?.id}
                              style={
                                timeRange?.performer?.includes(performer?.id)
                                  ? { backgroundColor: "red", color: "white" }
                                  : { backgroundColor: "white", color: "black" }
                              }
                            >
                              {performer?.name}
                            </p>
                          ))}
                      </div>
                    </>
                  )}
                  {/* </select> */}
                </div>

                <div className="col-lg-6">
                  {/* <video
                    controls
                    style={{ width: "100%", margin: "auto" }}
                    src={parseUrlFromModel(content)}
                    // ref={videoRef}
                    ref={(el) => (videoRefs.current[index] = el)}
                    onPlay={() => handlePlay(index)}
                    onLoadedMetadata={handleLoadedMetadata}
                  /> */}
                  {/* <div style={{ display: 'flex', gap: '8px' }}>
                    <div className='duration-display'></div>
                  </div> */}
                  {/* <input
            type="range"
            min="0"
            max="1"
            step="0.1"
            value={volumeLevels[index]}
            onChange={(event) => handleVolumeChange(index, event)}
          /> */}
                  <video
                    controls={false}
                    style={{ width: "100%", margin: "auto" }}
                    src={parseUrlFromModel(content)}
                    ref={(el) => (videoRefs.current[index] = el)}
                    // onPlay={() => handlePlay(index)}
                    onLoadedMetadata={() => handleLoadedMetadata(index)}
                  />

                  <div
                    style={{
                      width: "93%",
                      position: "relative",
                      left: "15px",
                      bottom: "32px",
                      background: "black",
                      height: "5px",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        bottom: "15px",
                        color: "white",
                      }}
                    >
                      <div style={{ display: "flex", gap: "8px" }}>
                        {!isPlaying[index] ? (
                          <FaPlay onClick={() => handlePlayClick(index)}>
                            play
                          </FaPlay>
                        ) : (
                          <FaPause onClick={() => handlePauseClick(index)}>
                            pause
                          </FaPause>
                        )}
                        <div
                          className="duration-display"
                          style={{ display: "flex", color: "white" }}
                        ></div>
                      </div>
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        background:
                          timeRange.startTime && timeRange.endTime
                            ? colorslist[index % colorslist.length]
                            : "transparent",
                        ...calculateBackgroundStyle(timeRange),
                        height: "5px",
                        cursor: "pointer",
                      }}
                    ></div>
                  </div>

                  {/* <video
                    controls
                    style={{ width: "100%", margin: "auto" }}
                    src={videoSrcs[index] || parseUrlFromModel(content)}
                    ref={(el) => (videoRefs.current[index] = el)}
                    onLoadedMetadata={() => handleLoadedMetadata(index)}
                  /> */}
                  {/* http://localhost:6063/content/api/perfomer/perfomer/trim-video?url=https://pub-049ff98eab794cb3866391f656ea8c6c.r2.dev/20MAY2024_USs.mp4&startTime=00:00:00&endTime=00:00:20 */}
                  {/* <video
                    controls
                    style={{ width: "100%", margin: "auto" }}
                    src={`${process.env.REACT_APP_contentBasePath}perfomer/perfomer/trim-video?url=${parseUrlFromModel(
                      content
                    )}&startTime=${timeRange?.startTime}&endTime=${
                      timeRange.endTime
                    }`}
                  /> */}
                </div>

                {timeRange?.id ? (
                  <div
                    className="col-md-1 m-auto"
                    style={{ cursor: "pointer" }}
                    onClick={() => deleteTimeRange(timeRange?.id)}
                  >
                    <i className="fa fa-trash"></i>
                  </div>
                ) : (
                  <div
                    className="col-md-1 m-auto"
                    style={{ cursor: "pointer" }}
                    onClick={() => deleteTimeRangeNewly(index)}
                  >
                    {/* new entry delete */}
                    <i className="fa fa-trash"></i>
                  </div>
                )}

                <div>
                  <label className="mt-4">
                    <h5>CH Description</h5>
                  </label>
                  <textarea
                    type="text"
                    cols={30}
                    rows={5}
                    className="form-control"
                    placeholder="Enter description here"
                    value={timeRange?.description}
                    onChange={(e) =>
                      handleChangeTimeRange(
                        index,
                        "description",
                        e.target.value
                      )
                    }
                  />
                </div>
                <button
                  onClick={() => handleAddTimeRangeInNextIndex(index)}
                  className="btn btn-secondary btn-block mt-3 me-3"
                >
                  +
                </button>
              </div>
            ))}

            {/* Button to add new time range */}
            <div className="row mb-3 btn-box">
              <div className="col-md-12">
                <div className="d-flex align-items-center gap-3 flex-wrap mt-1">
                  {timeRanges.length == 0 && (
                    <button
                      type="submit"
                      onClick={handleSubmit}
                      className="btn btn-dark btn-block"
                    >
                      Create
                    </button>
                  )}

                  {timeRanges.length > 0 && (
                    <button
                      type="submit"
                      onClick={handleSubmit}
                      className="btn btn-dark btn-block"
                      disabled={loading}
                    >
                      {loading ? "Updating..." : "Update"}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default CreateStamps;
